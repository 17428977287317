




























































import {Component, Vue} from 'vue-property-decorator'
import {getCourseByIdAsync} from '@/api/course'
import {CourseDto} from '@/dto/CourseDto'
import {makeOrder} from '@/api/courseOrder'
import {CourseOrderDto} from '@/dto/CourseOrderDto'
import {Lazyload} from 'vant'

Vue.use(Lazyload)

@Component({})
export default class CourseBuy extends Vue {
  private courseInfo: CourseDto = {
    coaches: [],
    courseImgs: [],
    category: 0,
    courseTime: '',
    endDateTime: '',
    helpline: '',
    id: '',
    limitNum: 0,
    name: '',
    openingDate: '',
    originalPrice: 0,
    price: 0,
    remark: '',
    startDateTime: '',
    buyNum: 0
  }
  private images = new Array()
  private courseBuyTime = 1
  private loading = false

  get courseId() {
    return this.$route.query.courseId;
  }

  private async mounted() {
    await this.getInfo()
  }

  private async getInfo() {
    if (this.courseId) {
      this.courseInfo = await getCourseByIdAsync(this.courseId);
      if (this.courseInfo.courseImgs.length > 0) {
        this.images = this.courseInfo.courseImgs.map((v: any) => v.fullName)
      }
    }
  }

  private async handleBuyOrder() {
    if (this.courseId) {
      const orderData = {
        courseId: this.courseId,
        remark: this.courseInfo.name,
        orderState: 0,
        price: this.courseInfo.price * this.courseBuyTime,
        discountPrice: this.courseInfo.price * this.courseBuyTime,
        courseTime: this.courseBuyTime,
        courseEnum: this.courseInfo.category
      }

      this.loading = true
      const result = await makeOrder(orderData);
      this.loading = false
      await this.$router.push({
        path: '/user/course-order',
        query: {
          orderId: result.id
        }
      });
    }
  }
}
